@use "sass:math";
@import 'mixins';
@import './guidelines_variables';
@import 'bootstrap.overrides';
@import 'fonts';
@import '~react-selectize/themes/index.css';
@import '~react-image-crop/dist/ReactCrop.css';
@import '~react-phone-input-2/lib/style.css';
@import './modules/stickyTable';
@import './modules/easy';
@import './modules/spinnerAnimation';
@import './modules/blog';
@import './modules/pages';
@import './guidelines';
@import './modules/scrollbar';

@import 'simplebar-react/dist/simplebar.min.css';
@import '~react-h5-audio-player/src/styles.scss';
@import '~react-google-flight-datepicker/dist/main.css';
@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';
@import '~react-time-picker/dist/TimePicker.css';
@import './custom_simplebar.scss';
@import '~react-datepicker/dist/react-datepicker.css';
@import './custom_react_datepicker.scss';

body,
#content {
  height: 100%;
}

body {
  position: static !important; // Prevent height cut with automatic intercom notys (#10158)
}

.show-desktop {
  display: block !important;
}
.show-mobile {
  display: none !important;
}
@include breakpoint-sm {
  .show-desktop {
    display: none !important;
  }
  .show-mobile {
    display: block !important;
  }
}

// DateRangePicker
.daterange_picker_custom {
  padding-top: 12px;
  font-family: $content_font;
  font-size: 15px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  %daterange_picker_option {
    font-size: 15px;
    font-weight: initial;
    color: $blue;
    padding: 4px 0px;
    cursor: pointer;
  }

  > div {
    display: inline-block;
    position: relative;
    top: -2px;
    vertical-align: middle;
    padding: 0 5px;

    :global(.DateRangePickerInput) {
      border: none;
    }
    :global(.DateInput__input) {
      cursor: pointer;
    }
    :global(.DateInput__display-text) {
      @extend %daterange_picker_option;
    }
    :global(.DateRangePicker__picker) {
      line-height: 40px;
      :global(.DayPicker) {
        padding-bottom: 20px;
      }
    }
    :global(.DateRangePicker__picker),
    :global(.DateInput--with-caret::before),
    :global(.DateInput--with-caret::after) {
      margin-top: -30px;
    }
    :global(.CalendarMonth__caption) {
      padding-top: 10px;
      padding-bottom: 25px;
      margin-top: 3px;
    }
    :global(.CalendarDay--selected-span.CalendarDay--hovered) {
      // background: darken($dates-blue-light, 10%);
      // border: 1px double darken($dates-blue-light, 10%);
    }
    :global(.DayPickerKeyboardShortcuts__show--bottom-right:hover) {
      border-right: 33px solid darken($blue, 10%);
    }
    :global(.CalendarDay--blocked) {
      span {
        display: block;
        line-height: 38px;
      }
    }
  }
  .daterange_picker_preset_group {
    @media screen and (max-width: 700px) {
      // wrap presets to the next line
      flex-basis: 100%;
      .daterange_picker_preset:first-child {
        border-left: none;
      }
    }
  }
  .daterange_picker_preset {
    display: inline-block;
    @extend %daterange_picker_option;
    padding: 0 10px;
    border-left: 1px solid #c3c3c3;
    &.daterange_picker_preset_applied {
      cursor: default;
      color: #828282;
    }
  }
}

// Buttons
.btn-fancy {
  background-color: $btn-fancy-blue;
  border: 0;
  border-radius: 4px;
  box-shadow: none;
  color: $white;
  font-family: $content_font;
  font-size: 14px;
  font-weight: normal;
  height: 40px;
  line-height: 40px;
  min-width: 130px;
  outline: 0;
  padding: 0 20px 1px;

  &:hover:not(:disabled) {
    background-color: $btn-fancy-blue-hover;
  }

  &:active {
    background-color: $btn-fancy-blue-active;
  }

  &:focus:active {
    outline: 0;
  }

  &:disabled {
    opacity: 0.3;
  }

  &.btn-fancy-filled {
    color: $btn-fancy-white;
  }

  &.btn-fancy-round {
    border-radius: 3px;
  }
  &.btn-fancy-round-fullheight {
    border-radius: 40px;
  }
  &.add {
    &::before {
      content: '+';
      color: white;
      font-family: serif;
      font-weight: 100;
      line-height: 40px;
      font-size: 24px;
      float: left;
      margin-left: 0px;
      margin-right: 10px;
    }
  }
}

.btn-fancy-cancel,
.btn-fancy-danger {
  background-color: transparent;

  &:hover {
    background-color: transparent;
  }

  &:active,
  &:focus,
  &:focus:active {
    background-color: transparent;
  }
}

.btn-fancy-cancel {
  color: $btn-fancy-blue;

  &:hover {
    color: $btn-fancy-blue-hover;
  }

  &:active,
  &:focus,
  &:focus:active {
    color: $btn-fancy-blue-active;
  }
}

.btn-fancy-danger {
  color: $btn-fancy-red;

  &:hover {
    color: $btn-fancy-red-hover;
  }

  &:active,
  &:focus,
  &:focus:active {
    color: $btn-fancy-red-active;
  }
}

.btn-fancy-red {
  background-color: $btn-fancy-red;

  &:hover {
    background-color: $btn-fancy-red-hover;
  }

  &:active,
  &:focus,
  &:focus:active {
    background-color: $btn-fancy-red-active;
  }
}

.btn-fancy-blue {
  background-color: $btn-fancy-blue;

  &:hover {
    background-color: $btn-fancy-blue-hover;
  }

  &:active,
  &:focus,
  &:focus:active {
    background-color: $btn-fancy-blue-active;
  }
}

.btn-fancy-green {
  background-color: $btn-fancy-green;

  &:hover {
    background-color: $btn-fancy-green-hover;
  }

  &:active,
  &:focus,
  &:focus:active {
    background-color: $btn-fancy-green-active;
  }
}

.btn-fancy-pink {
  background-color: $btn-fancy-pink;

  &:hover {
    background-color: $btn-fancy-pink-hover;
  }

  &:active,
  &:focus,
  &:focus:active {
    background-color: $btn-fancy-pink-active;
  }
}

.btn-fancy-grey {
  background-color: $btn-fancy-grey;

  &:hover {
    background-color: $btn-fancy-grey-hover;
  }

  &:active,
  &:focus,
  &:focus:active {
    background-color: $btn-fancy-grey-active;
  }
}

.btn-fancy-white {
  background-color: transparent;
  border: 1px solid $btn-fancy-white;
  color: $btn-fancy-white;

  &:hover {
    background-color: $btn-fancy-white;
    color: $black;
  }
}

.btn-blue {
  @extend .btn-fancy;
  background-color: $blue;

  &:hover,
  &:active {
    background-color: $blue-hover;
  }
}

a.btn-black.btn-loading {
  display: flex;
  align-items: center;

  &[disabled] {
    opacity: 1;
    background-color: #4ce589 !important;
  }
}

.btn-black {
  background-color: $black;
  color: $white;
  transition: opacity 0.2s ease-in-out;
  border-radius: 40px;
  height: 40px;
  line-height: 40px;
  font-size: 16px;
  font-weight: bold;
  border: none;
  text-align: center;
  cursor: pointer;

  &[disabled] {
    opacity: 1;
    background: #ccc !important;
    pointer-events: none;
    user-select: none;
  }

  &:hover {
    color: $white;
    background-color: #1b1b1b;
  }
  &:active,
  &:focus,
  &:focus:active {
    background-color: rgba($color: #000000, $alpha: 0.84);
    outline: none;
    color: $white;
  }

  &.btn-loading {
    background-color: #4ce589 !important;
    .spinner {
      @extend .spinnerAnimation;
      margin: 0 auto;
      display: block;
      border-top: 3px solid white;
      border-right: 3px solid white;
      border-bottom: 3px solid white;
      border-left: 3px solid transparent;
    }
  }

  &.btn-fill {
    width: 100%;
  }
}

.btn-rectangle {
  border-radius: 0;
}

// Links
.iorad-link {
  text-decoration: none;
  line-height: 21px;
  display: inline-block;
  font-size: 14px;
  color: $btn-fancy-blue;
  padding: 0;
  border-radius: 0 !important;
  border-bottom: 1px solid transparent;

  &[href],
  & {
    &:hover,
    &:focus {
      color: #ff291c;
      text-decoration: none;
      border-bottom: 1px solid #ff291c;
    }
  }
}

.link-fancy-blue {
  color: $link-fancy-blue;
  cursor: pointer;
  text-decoration: none;

  &:hover,
  &:focus {
    color: $btn-fancy-blue;
    text-decoration: none;
    outline: none;
  }
}

.link-btn {
  appearance: button-bevel;
  -webkit-appearance: button-bevel;
  -moz-appearance: button-bevel;
  padding: 0 60px;
  font-weight: 100;
  color: $white;

  &:hover,
  &:focus {
    color: $white;
  }
}

// Dropdown menus
.dropdown-toggle {
  background-color: $text-field-bg;
  border: 1px solid transparent;
  border-radius: 3px;
  color: $text-field-text-color;
  padding: 9px 7px;

  &:hover {
    @include placeholder($text-field-placeholder-hover);
    background-color: $text-field-bg;
    border: 1px solid $text-field-border-hover;

    + .label {
      color: $label-hover;
    }
  }

  &:focus {
    @include placeholder($text-field-placeholder);
    background-color: $text-field-bg-focus;
    border: 1px solid $text-field-border-focus;
    color: $text-field-text-color;

    + .label {
      color: $label-focus;
    }
  }

  &:disabled {
    opacity: 0.5;
  }

  > span + span {
    // Caret container
    float: right;
    margin-right: 5px;
  }

  .caret {
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px dashed $dropdown-caret;
  }
}

.dropdown-menu {
  min-width: 20px;
}

$text-field-width: 140px;
.iorad-popover {
  $arrow-size: 20px;

  border-radius: 0;
  z-index: 99;
  padding: 0;
  position: absolute;

  .arrow {
    border-bottom-color: rgba(0, 0, 0, 0.08);
  }

  .iorad-popover-content {
    padding: 0;
    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;

      li {
        margin: 0;
        display: block;
        padding-left: 20px;
        padding-top: 9px;
        padding-bottom: 9px;

        &:hover {
          background-color: $iorad-popover-item-hover;
          cursor: pointer;
        }
      }
    }
  }

  ul {
    background-color: #fff;
    box-shadow: 1px -1px 20px rgba(0, 0, 0, 0.176);
    left: -(math.div($text-field-width, 2));
    top: 0;
    list-style: outside none none;
    margin: 0;
    min-width: $text-field-width;
    position: absolute;
    text-align: left;
    z-index: 999;

    li:first-child {
      margin-top: -20px;
    }

    &::before {
      // Arrow
      position: relative;
      content: '';
      width: 0;
      height: 0;
      left: math.div($text-field-width, 2) - math.div($arrow-size, 2);
      top: -29px;
      border-style: solid;
      border-width: 0 math.div($arrow-size, 2) math.div($arrow-size, 2) math.div($arrow-size, 2);
      border-color: transparent transparent #ffffff transparent;
    }

    a {
      border: 0;
      color: $text-field-text-color;
      font-size: 16px;
      text-decoration: none;
      width: 100%;
      height: 100%;
    }
  }
}

.iorad-popover-toggle {
  @extend .dropdown-toggle;

  cursor: pointer;

  &::after {
    // Down arrow
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    left: $text-field-width - 15;
    top: 15px;
    transform: rotate(180deg);
    border-style: solid;
    border-width: 0 5px 5px 5px;
    border-color: transparent transparent $text-field-text-color transparent;
  }

  &:hover {
    .popover-toggle-placeholder {
      color: $text-field-placeholder-hover;
    }
  }

  > span {
    pointer-events: none;

    &.popover-toggle-placeholder {
      color: $text-field-placeholder;
    }
  }
}

// Text input fields
.text-field {
  @include placeholder($text-field-placeholder);
  background-color: $text-field-bg;
  border: 1px solid transparent;
  border-radius: 3px;
  color: $text-field-text-color;
  font-family: 'Libre Franklin', sans-serif;
  font-size: 15px;
  font-weight: 400;
  height: 40px;
  outline: 0;
  padding: 6px 22px;
  width: 360px;
  caret-color: var(--site-theme-color);

  &:hover:not(:disabled) {
    color: rgba(0, 0, 0, 0.4);

    + .label {
      color: $label-hover;
    }
  }

  &:focus:not(:disabled) {
    @include placeholder($text-field-placeholder);
    background-color: $text-field-bg-focus;
    // border: 1px solid $text-field-border-focus;
    border-width: 1px;
    border-color: #000;
    color: $text-field-text-color;

    + .label {
      color: $label-focus;
    }
  }

  &:disabled {
    opacity: 0.5;
  }

  &.tf-v1 {
    // height: 40px;
    color: #000000;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    background: white;
    padding-bottom: 7px;
    &:focus:not(:disabled) {
      border-color: black;
    }
    &::placeholder {
      /* Chrome/Opera/Safari */
      color: rgba(0, 0, 0, 0.4);
    }
    &.error {
      padding-bottom: 7px;
      border-color:var(--site-theme-color);
    }
  }
}
.text-field-label {
  font-size: 13px;
  line-height: 18px;
  color: rgba(0, 0, 0, 0.4);
  &.error {
    color: var(--site-theme-color);
  }
}
.text-field-error {
  color: var(--site-theme-color);
  font-weight: 300;
}
.text-field-group {
  &:focus-within {
    .text-field-label.error {
      color: #000000;
    }
    .text-field-error {
      display: none;
    }
  }
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 40px white inset !important;
}

// Tables
table {
  border: 1px solid $lightgrey;
  border-collapse: collapse;
  margin-top: 20px;
}

.table-data-row {
  td {
    border-bottom: 1px solid $table-data-border;
    padding: 30px 0;
  }

  &:hover {
    background-color: $table-data-highlight;
  }
}

.table-header-row {
  border: 1px solid $table-header-border;
  color: $table-header-color;
  font-size: 10px;
  font-weight: bold;
  letter-spacing: 2px;
  text-transform: uppercase;

  th {
    padding: 8px 0;
  }
}

.icon {
  height: 20px;
  width: 20px;
}

// Checkboxes
.checkbox {
  &.disabled {
    label {
      color: #727c80;
    }
  }
}
.checkbox-regular {
  display: inline-block;
  position: relative;

  input {
    cursor: pointer;
    height: 17px;
    left: 0;
    margin: 0;
    opacity: 0;
    position: absolute;
    top: 3px;
    width: 17px;
    z-index: 1;
  }

  label {
    cursor: pointer;
    font-size: 14px;
    font-weight: normal;
    padding-left: 24px;

    @media only screen and (min-width: 300px) and (max-width: 370px) {
      font-size: 12px;
    }

    &:after {
      background-color: $checkbox-regular-background;
      background-position: center center;
      background-repeat: no-repeat;
      border-radius: 2px;
      content: '';
      height: 16px;
      left: 0;
      position: absolute;
      top: 2px;
      width: 16px;
    }
  }

  input:hover ~ label::before {
    background: url('../assets/img/icon_check.svg') no-repeat;
    background-position: 2px 0;
    background-size: 14px 13px;
    content: '';
    height: 16px;
    left: 0;
    opacity: 0.4;
    pointer-events: none;
    position: absolute;
    top: 2px;
    width: 16px;
    z-index: 2;
  }

  input:checked ~ label::after {
    background-image: url('../assets/img/icon_check.svg');
    background-position: 2px 0;
    background-size: 14px 13px;
  }
}

// Notifications
.notification-wrapper {
  position: relative;
}

$notificationsZIndex: 2147483002; // Above embed panel/button
.notifications-tc {
  bottom: auto;
  box-sizing: border-box;
  height: auto;
  left: 0;
  margin: 0;
  min-width: 347px;
  padding: 0;
  pointer-events: none;
  position: absolute;
  display: -webkit-flex;
  display: flex;
  justify-content: center;
  right: 0;
  text-align: center;
  top: 0;
  width: 100%;
  z-index: $notificationsZIndex;
}
.notifications-tr {
  z-index: $notificationsZIndex;
  position: fixed;
  top: 80px;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: right;
  align-items: flex-end;
  .notification {
    // the gap for bouncing animation
    right: -25px;
  }
  @media (max-width: 767px) {
    top: 0;
    left: 0;
    .notification {
      right: 0;
      width: 100%;
      padding-right: 0;
    }
  }

  &.belowSocialPopupHeader {
    top: 48px;
  }
}

.notification {
  position: relative;
  display: flex;
  align-content: space-between;
  width: 393px;
  height: auto;
  padding-right: 25px;
  margin-bottom: 2px;

  &.has-action {
    width: 546px;

    .notification-message {
      min-width: 310px;
    }
  }

  &.notification-visible {
    top: 0;
  }

  &.notification-hidden {
    top: -100px;
  }

  :global(.default-cursor) {
    cursor: default;
  }

  & > .notification-action-wrapper {
    height: 36px;
    margin-block: auto;
    margin-left: 9px;
    flex-shrink: 0;
    position: relative;
    // draw the right edge the same color with button for bounce animation
    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: -25px;
      height: 100%;
      width: 25px;
    }
  }
  .notification-action-button {
    padding: 0 24px;
    color: #fff;
    background-color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 98px;
    height: 100%;
    cursor: pointer;
    column-gap: 4px;

    span {
      font-weight: 600;
      font-size: 15px;
      line-height: 20px;
    }
  }
  .notification-message {
    line-height: 20px;
    font-size: 15px;
    font-weight: 400;
    font-family: 'default_Libre Franklin', 'Libre Franklin', sans-serif;
    word-wrap: break-word;
    padding-top: 9px;
    padding-bottom: 7px;
    display: flex;
    align-items: center;
    flex-grow: 1;
    overflow: hidden;

    &.no_action {
      padding-right: 40px;
    }
  }
  .notification-custom-content {
    & > span {
      @extend .notification-message;
      display: flex;
      align-items: center;
      padding-right: 0;
    }
    a {
      @extend .notification-action-button;
      margin-left: 22px;
      &:hover {
        text-decoration: none;
      }
    }
  }

  &.notification-not-dismissible {
    padding-right: 0;
  }

  &.notification-transition-enter-active {
    animation-fill-mode: forwards;
    animation-duration: 0.7s;
    animation-name: bounceInRight;
  }
  &.notification-transition-exit-active {
    animation-fill-mode: forwards;
    animation-duration: 0.7s;
    animation-name: bounceOutRight;
  }

  @include breakpoint-sm {
    min-height: 54px;
    width: 100%;

    &.has-action {
      width: 100%;
      .notification-message {
        min-width: auto;
        overflow-x: auto;
      }
    }


    & > .notification-action-wrapper {
      margin-block: 0;
      margin-left: 21px;
      height: auto;
    }

    .notification-action-button {
      span {
        font-size: 13px;
        line-height: 20px;
      }
    }

    .notification-message {
      line-height: 18px;
      font-size: 13px;
    }
  }
}

.notification-success {
  background-color: $notification-success-bg;
  a {
    color: #fff176;
    &:hover {
      color: #ffeb3b;
    }
  }
}

.notification {
  background-color: $notification-error-bg;
  color: var(--site-theme-color);
  .notification-action-wrapper::after {
    background-color: var(--site-theme-color);
  }
  .notification-action-button {
    background-color: var(--site-theme-color);
  }
  .notification-dismiss {
    &::before,
    &::after {
      background-color: var(--site-theme-color);
    }
  }
}

.notification-dismiss {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding-left: 15px;
  padding-right: 22px;

  @include breakpoint-sm {
    padding-left: 25px;
    padding-right: 21px;
  }
}

// Modals
.modal-header {
  .close {
    outline: none;

    span {
      background: url('../assets/img/icons/close-pop-up.svg') no-repeat;
      color: transparent;
      display: block;
      font-size: 0;
      height: 14px;
      text-decoration: none;
      width: 14px;
    }
  }
}

.modal {
  padding: 0 !important;
  width: 100vw;
  height: 100vh;
  overflow: hidden;

  .modal-dialog {
    margin: 0 !important;

    .modal-body {
      margin: 0 auto;
      padding: 0;
      top: calc(50% - 60px);
      transform: translateY(calc(-50% - 30px));
      width: 100vw;
      margin: 0 auto;
    }
  }

  .modal-header {
    .close {
      position: relative;
      z-index: 1;
      margin-right: 15px;
      margin-top: 15px;
    }
  }

  .modal-content {
    min-width: 700px;
    width: 100vw !important;
    height: 100vh !important;
  }

  .modal-dialog.fullscreen {
    top: 0;
    left: 0;
    overflow: hidden;
    height: 100vh;
    margin: 0;
    padding: 0;
    width: 100vw;
    position: absolute;
    width: 100vw;

    .modal-content {
      height: 100%;
    }

    .modal-dialog .modal-header .close {
      margin-right: 15px;
      margin-top: 15px;
    }
  }
}

@mixin timing-function {
  animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

@keyframes bounceInRight {
  from,
  60%,
  75%,
  90%,
  to {
    @include timing-function;
  }
  from {
    opacity: 0;
    transform: translate3d(3000px, 0, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(-25px, 0, 0);
  }
  75% {
    transform: translate3d(10px, 0, 0);
  }
  90% {
    transform: translate3d(-5px, 0, 0);
  }
  to {
    transform: none;
  }
}

@keyframes bounceOutRight {
  20% {
    opacity: 1;
    transform: translate3d(-20px, 0, 0);
  }
  to {
    opacity: 0;
    transform: translate3d(2000px, 0, 0);
  }
}

@media (min-width: 768px) {
  :global(.modal-dialog) {
    width: auto;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  // IE10+
  :global(.modal .modal-dialog .modal-body) {
    top: 0px;
  }
}

// Scrollbars
.scrollbars {
  & > div + div + div {
    opacity: 0 !important;
    top: 6px !important;
    bottom: 6px !important;
    right: 6px !important;
    transition: opacity 0.3s ease-in-out;
  }
}

.scrollbars:hover {
  & > div + div + div {
    opacity: 1 !important;
  }
}

// Mixins

html.iorad-embed-panel-open:not(.iorad-embed-hubspot-visible) body {
  & > #hubspot-messages-iframe-container,
  & > #hubspot-conversations-inline-parent {
    z-index: unset !important;
  }
}

body.hide-hubspot > #hubspot-messages-iframe-container,
body.hide-hubspot > #hubspot-conversations-inline-parent {
  display: none;
}

body.hide-iorad-widget > .iorad-embed-widget:not(.close-widget) {
  display: none;
}

// Hide hubspot and iorad button on mobile editor
@include breakpoint-sm {
  html[data-pathname^='/editor/'] {
    body > #hubspot-messages-iframe-container,
    body > #hubspot-conversations-inline-parent,
    body > .iorad-embed-widget {
      display: none !important;
    }
  }
}

// Animations

.animation-blink {
  animation: blinker 2s ease-out infinite;
}

@keyframes blinker {
  50% {
    opacity: 0.2;
  }
}

.mb-120-md {
  @include breakpoint-md {
    margin-bottom: 120px;
  }
}
.mb-120-lg {
  @include breakpoint-lg {
    margin-bottom: 120px;
  }
}

// Additional space for iorad widgets for mobile screens
// Even when iorad-embed-widget-visible class is present on html, its widget seems to be hidden by other injected styles.
@media (max-width: 767px) {
  html {
    &.iorad-embed-widget-visible,
    &.iorad-embed-combine-hubspot {
      main:not(.tutorials) {
        //padding-bottom: 96px;
      }
    }
  }
}

@media print {
  .bm-menu-wrap {
    display: none;
  }
}

.ts_popup {
  padding: 0;
  margin: 80px;

  :global(.gl-content-wrapper) {
    width: auto;
    margin: 0;
    padding: 0;
    margin-top: 40px;

    :global(.popup-footer) {
      :global(.popup-button) {
        background-color: var(--site-theme-color);
        border-radius: 40px;
        color: #000;
        cursor: pointer;
        display: flex;
        height: 64px;
        justify-content: center;
        letter-spacing: 0.5px;
        line-height: 64px;
        text-align: center;
        width: 121px;
        font-weight: bold;
        font-size: 16px;
        color: #ffffff;
        margin: 0;
        align-items: center;

        :global(.keyname) {
          display: none;
        }
      }
    }
  }

  :global(.popup-close) {
    display: none;
  }
}

// GOOGLE TRANSLATE

html.translated-ltr,
html.translated-rtl {
  // prevent body from being pushed down for the top bar (which is hidden anyway)
  body {
    top: 0 !important;
  }
}

iframe#\:2\.container {
  // fix disappearing bar in chrome
  // (http://stackoverflow.com/questions/11258877/fixed-element-disappears-in-chrome)
  -webkit-transform: translateZ(0);
}
iframe#\:2\.container {
  z-index: 1;
  width: 100%;
}

// hide google translate top bar
iframe#\:0\.container,
iframe#\:2\.container {
  display: none;
}

// hide google translate "original text" popup and highlight
// credits https://stackoverflow.com/a/8531408/1418049
.goog-tooltip,
.goog-tooltip:hover,
#goog-gt-,
#goog-gt-:hover,
#goog-gt-tt,
#goog-gt-tt:hover {
  display: none !important;
}
.goog-text-highlight {
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
}

body .goog-te-menu-frame {
  @include box-shadow(0 0 4px $grey);
}

// this google translate element
// causes continous re-renders
// and a performance hit (#6612)
svg.goog-te-spinner {
  display: none;
}

.nav-tabs-v1 {
  .nav-tabs {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    li {
      color: #bdbdbd;
      position: relative;
      a {
        color: inherit;
      }
      &:hover {
        border-bottom: 1px solid #ff291c;
        color: #ff291c;
      }
      &.active {
        border-bottom: 1px solid black;
        color: black;
      }
    }
  }
}

.grecaptcha-badge {
  display: none !important;
}
body.route-signup {
  .grecaptcha-badge {
    display: block !important;
    visibility: visible !important;
    bottom: 100px !important;
  }

  &.extensionEmbed {
    .grecaptcha-badge {
      bottom: 0 !important;
    }
  }
}

// GOOGLE TRANSLATE END

// #7442, #7446: hyperlink refactor;

a {
  // no href or default state
  color: #000;
  text-decoration: none;
  transition: color 0.3s ease;

  &:hover,
  &:focus {
    color: var(--site-theme-color);
    text-decoration: none;
  }

  &[href='#'],
  &[href=''],
  &[href='javascript:;'] {
    &:hover,
    &:focus {
      text-decoration: none;
    }
  }
}
// is button
a {
  &.btn-fancy,
  &.btn {
    &:hover,
    &:focus {
      text-decoration: none;
    }
  }
}

.easy-context-menu {
  a {
    &:hover,
    &:focus {
      text-decoration: none;
    }
  }
}

.mobileBreak {
  @include breakpoint-sm {
    display: block;
  }
}

.break {
  display: block;
}

.desktopBreak {
  display: block;
  @include breakpoint-sm {
    display: none;
  }
}

// end #7442

.error-msg {
  color: var(--site-theme-color);
  display: flex;
  align-items: flex-start;
  font-weight: 300;
  height: 40px;
}

.text-input-group {
  .error-msg {
    color: transparent;
  }
  &.input-error {
    .error-msg {
      color: var(--site-theme-color);
    }
    .easy-autocomplete input {
      border-color: var(--site-theme-color);
      &:focus {
        border-color: $autocompleteInputBorderColor;
      }
    }
  }
}

input,
textarea {
  &:focus {
    outline: none;
  }
}

.file-input {
  display: inline-block;
  & > input[type='file'] {
    display: none;
  }
}

@include breakpoint-md-plus {
  .wrapDesktop {
    width: 0;
    height: 0;
    flex-basis: 100%;
  }
}

::-webkit-scrollbar {
  width: 11px;
  height: 11px;
}

::-webkit-scrollbar-thumb {
  border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  background-color: rgba(0, 0, 0, 0.2);
}

::-webkit-scrollbar-track {
  background: transparent;
}
