.blog {
  a[href] {
    &:hover, &:focus {
      text-decoration: none;
    }
  }

  .medium-widget-article {
    &__item {
      padding: 12px 32px;

      @media screen and (max-width: 767px) {
        width: 100%;
        min-width: 100%;
      }
    }

    &__row {
      flex-wrap: wrap;

      @media screen and (max-width: 767px) {
        margin-left: 0;
        margin-right: 0;
      }
    }

    &__image {
      border: 1px solid rgba(0,0,0,.08);
      height: 272px;

      @media screen and (max-width: 767px) {
        height: auto;
      }

      img {
        max-width: 100%;
        max-height: 100%;
        margin: 0;
        object-fit: cover;
      }
    }

    &__content {
      padding-top: 6px;
      margin-bottom: 18px;
    }

    &__column {
      margin-bottom: 16px;
      box-shadow: 0px 1px #e8e8e8;
    }

    &__title {
      font-weight: 500;
      font-size: 26px;
      padding-bottom: 2px;
      padding-top: 5px;
      line-height: 1.2;

      @media screen and (max-width: 767px) {
        font-size: 20px;
      }
    }
  }

  .medium-widget {
    &__wrapper {
      max-width: 900px;
      margin: 0 auto;

      padding-left: 15px;
      padding-right: 15px;

      @media screen and (max-width: 767px) {
        padding-left: 10px;
        padding-right: 10px;
      }
    }
  }


}
