.simplebar-track {
  &.simplebar-vertical {
    width: 15px !important;
  }
  &.simplebar-horizontal {
    height: 15px !important;
  }
}

.simplebar-scrollbar {
  &::before {
    left: 5px !important;
    right: 5px !important;
    border-radius: 0 !important;
  }
  &.simplebar-visible::before {
    opacity: 0.1 !important;
  }
}

.simplebar-placeholder {
  width: 100% !important;
}

.simplebar-content-wrapper.disabled {
  overflow: hidden !important;
}
