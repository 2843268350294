html[data-pathname^="/connectIntegrations/"] {
  #page-wrap .notification-tc {
    z-index: 2147482997;
  }
}

html[data-pathname^="/tutorials/"] #page-wrap {
  background-color: $lightgreytutorials;
}

// global
// matches "/help-center/1" but not "/help-center-settings"
html[data-pathname^="/help-center/"] #page-wrap,
html[data-pathname^="/library/"] #page-wrap {
  height: 100%;
  background-color: #F7F7F7;
}

html[data-pathname^="/help-center-settings/widget"] {
  #iorad-embed-modal.blankOverlay {
    visibility: hidden;
  }

  $topNavBarHeightAuthorized: 48px;
  $panelBottom: 7px;
  .iorad-embed-panel, .iorad-embed-panel iframe {
    // Make sure the panel doesn't go above the site header (e.g. on zoom)
    // and stays inside the fake container
    max-height: calc(100vh - #{$topNavBarHeightAuthorized + $panelBottom}) !important;
    height: 760px !important;
  }
  &[data-widget-preview-position="tr"],
  &[data-widget-preview-position="tl"] {
    .iorad-embed-panel, .iorad-embed-widget {
      // Make sure the panel doesn't go above the site header (e.g. on zoom)
      // and stays inside the fake container
      transform: translateY($topNavBarHeightAuthorized) !important;
    }
  }

  // hubspot is hidden in preview
  #hubspot-messages-iframe-container,
  #hubspot-conversations-inline-parent {
    display: none !important;
  }
}

html[data-pathname^="/iframes/zendesk_sell/"] {
  .contentClassName {
    top: 0 !important;
  }

  .cc-banner,
  .iorad-embed-widget,
  .iorad-embed-panel,
  #hubspot-messages-iframe-container,
  #hubspot-conversations-inline-parent
  {
    display: none !important;
    visibility: hidden !important;
    opacity: 0 !important;
    z-index: -9999 !important;
  }
}

html[data-pathname^="/player/"],
html[data-pathname^="/help-center/"],
html[data-pathname^="/library/"],
html[data-pathname*="/thesquare/"], // * to also include the /product/:productName/thesquare/ URL
html[data-pathname^="/admin/"],
html[data-pathname^="/account/themeSettings"]
{
  // hide hubspot button & iorad widget button
  body {
    @extend .hide-hubspot;
    @extend .hide-iorad-widget;
  }
}

html[data-pathname*="/thesquare/"] { // * to also include the /product/:productName/thesquare/ URL
  // custom boostrap tooltip style
  .tooltip:not(#symbol-score-tooltip) { // use default tooltip for the score icon
    .tooltip-arrow {
      display: none;
    }
    .tooltip-inner {
      background: transparent;
      margin: 0;
      padding: 0;
      border-radius: 0;
    }
  }

  .tooltip#symbol-score-tooltip {
    .tooltip-arrow {
      display: none;
    }
    .tooltip-inner {
      border-radius: 0;
      padding: 5px 10px;
      font-size: 15px;
      line-height: 20px;
      font-weight: 400;
      color: black;
      background: #f4f4f4;
      text-align: left;
    }
  }

  font {
    box-shadow: none !important;
    background: none !important;
  }
}

html[data-pathname*="/help-center/"],
html[data-pathname*="/library/"]  {
  // custom boostrap tooltip style
  .tooltip.ioradical_profile_tooltip {
    .tooltip-arrow {
      display: none;
    }
    .tooltip-inner {
      background: transparent;
      margin: 0;
      padding: 0;
      border-radius: 0;
    }
  }

  font {
    box-shadow: none !important;
    background: none !important;
  }
}
