@import 'mixins/from-to';

/**
 * Override Bootstrap styles that you can't modify via variables here.
 *
 */

.container {
  @include from(768px) {
    width: 100%;
    max-width: 750px;
  }

  @include from(992px) {
    width: 100%;
    max-width: 970px;
  }

  @include from(1200px) {
    width: 100%;
    max-width: 1170px;
  }
}

.navbar-brand {
  padding-left: 50px;
  position: relative;
}

.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-nav > .active > a:hover,
.navbar-default .navbar-nav > .active > a:focus {
  color: #33e0ff;
  background-color: transparent;
}

.modal {
  text-align: center;
}

@media screen and (min-width: 768px) {
  .modal:before {
    content: ' ';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }
}

.modal-dialog {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
}

.modal-header {
  border: 0;

  button {
    opacity: 1;

    &:hover {
      background-color: transparent;
      opacity: 1;
    }
  }
}

.modal.fade .modal-dialog {
  transition: none;
  transform: none;
}

.modal-content {
  border: 0;
  border-radius: 0;
  box-shadow: 0 5px 40px rgba(0, 0, 0, 0.5);
}

// Mobile
@media screen and (max-width: 600px) {
  .fade.in.modal {
    padding-left: 0 !important;
  }
  .modal-body {
    padding: 10px;
  }
}


.popover[data-style="iorad"] {
  border-radius: 0;
  background-color: #f4f4f4;

  &.right > .arrow::after {
    border-right-color: #f4f4f4;
  }
  &.left > .arrow::after {
    border-left-color: #f4f4f4;
  }
  &.top > .arrow::after {
    border-top-color: #f4f4f4;
  }
  &.bottom > .arrow::after {
    border-bottom-color: #f4f4f4;
  }

  .popover-content {
    padding: 9px;
  }

  p {
    margin: 0;

    a {
      font-weight: 500;
      text-decoration: underline;
    }
  }
}

.popover[data-style="library"] {
  border-radius: 0;
  background-color: #fdf2fc;
  border-color: var(--site-theme-color);

  &.right > .arrow {
    border-right-color: var(--site-theme-color);
    &::after {
      border-right-color: #fdf2fc;
    }
  }
  &.left > .arrow {
    border-left-color: var(--site-theme-color);
    &::after {
      border-left-color: #fdf2fc;
    }
  }
  &.top > .arrow {
    border-top-color: var(--site-theme-color);
    &::after {
      border-top-color: #fdf2fc;
    }
  }
  &.bottom > .arrow {
    border-bottom-color: var(--site-theme-color);
    &::after {
      border-bottom-color: #fdf2fc;
    }
  }

  .popover-content {
    padding: 10px;
  }
}