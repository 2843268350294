@use "sass:math";
@import '~theme/mixins';
@import '~theme/modules/variables';
@import '~theme/guidelines';

$openTime: 0.1s;
$autocompleteInputBorderColor: rgba(0, 0, 0, 0.2);

.easy-popup-textfield {
  border-radius: 5px;
  width: 100%;
  float: left;
  text-align: left;
  border: 1px solid $light_grey;
  font-size: 16px;
  height: 64px;
  color: #000;
  padding: 0 24px;
  background-color: $light_grey;

  &:focus {
    background-color: #fff;
  }

  @include breakpoint-sm {
    height: 40px;
    padding: 0 16px;
  }
}

.easy-popup-label {
  font-family: 'Libre Franklin', sans-serif;
  font-weight: 300;
  line-height: 20px;
  color: #bdbdbd;
  font-size: 16px;
  margin-bottom: 13px;
  width: 100%;
}

.easy-popup-select {
  display: inline-block;
  border-radius: 5px;
  color: #000;
  width: 100%;
  background-color: $light_grey;

  &:focus {
    background-color: #fff;
  }

  @include breakpoint-sm {
    height: 40px;
    padding: 0 16px;
  }

  &.open {
    background-color: #fff;
  }

  .dropdown-menu {
    border-radius: 0 0 5px 5px;

    .no-results-found {
      padding: 8px 24px !important;
    }

    .option-wrapper > div {
      padding: 8px 24px !important;
    }
  }

  // ugly rewrite Bootstrap
  .react-selectize-control {
    font-family: 'Libre Franklin', sans-serif !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    font-weight: 300 !important;
    height: 64px !important;
    border: 1px solid $light_grey !important;
    padding: 0 0 0 24px !important;
    border-radius: 5px !important;
    background-color: transparent !important;
  }

  .react-selectize-placeholder,
  .react-selectize-reset-button-container,
  .react-selectize-search-field-and-selected-values {
    text-indent: 1px !important;
    height: 62px !important;
    min-height: 62px !important;
    line-height: 62px !important;
    padding-left: 1px !important;
    color: #828282;

    .resizable-input {
      padding: 0px 2px !important;
      margin: 0 !important;
      font-size: 16px !important;
    }

    span {
      line-height: 22px !important;
      border-radius: 2px !important;
      margin: 0 !important;
      font-weight: 300 !important;
      max-height: auto !important;
    }
  }

  .react-selectize-toggle-button-container {
    height: 64px !important;
  }
}

.easy-select {
  display: inline-block;
  height: 40px;
  line-height: 38px;
  width: 220px;
  position: relative;
  cursor: pointer;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  background-color: transparent;
  font-family: 'Libre Franklin', sans-serif;
  font-size: 14px;
  box-sizing: border-box;
  transition: border $openTime, background-color $openTime, color $openTime;
  &:hover {
    .placeholder {
      color: $text-field-placeholder-hover;
    }
  }
  .text {
    vertical-align: middle;
    max-width: calc(100% - 40px);
    overflow: hidden;
    white-space: nowrap;
    color: #000;
    display: flex;
    align-items: center;
    line-height: 40px;
  }
  input.text {
    width: 100%;
    border: none;
    padding: 0;
    background: transparent;
    cursor: default;
  }
  .placeholder {
    color: $text-field-placeholder;
  }
  .cursor {
    display: inline-block;
    vertical-align: middle;
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);

    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #444;
  }
  .focus {
    width: 0;
    height: 0;
    border: 0;
    margin: 0;
    padding: 0;
    position: absolute;
    opacity: 0;
    pointer-events: none;
  }
  .options-container {
    position: absolute;
    pointer-events: none;
    opacity: 0;
    background-color: #fff;
    width: 100%;
    top: 100%;
    transform: translate(0, -20%);
    border: 1px solid rgba(0, 0, 0, 0.2);
    z-index: 101;
  }
  .options {
    overflow-y: auto;
    overflow-x: hidden;
    .option {
      line-height: 20px;
      padding-top: 10px;
      padding-bottom: 10px;
      padding-left: 22px;
      cursor: pointer;
      transition: background-color 0.1s;
      &:hover {
        text-decoration-line: underline;
      }
    }
  }
  &.open {
    background-color: $text-field-bg-focus;
    color: $text-field-text-color;

    .options-container {
      pointer-events: all;
      opacity: 1;
      transform: translate(0);
    }
  }
  &.disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }
  &.h64 {
    height: 64px;
    width: 100%;
    font-size: 16px;
    font-weight: 300;
    .text {
      height: 100%;
      font-size: 16px;
      font-weight: 300;
    }
  }
  &.h36 {
    height: 36px;
    width: 100%;
    font-size: 14px;
    font-weight: 400;
    .text {
      height: 100%;
      font-size: 14px;
      font-weight: 400;
    }
  }
  &.textfield-like {
    // same with default textfield
    width: 360px;
    background-color: $light_grey;
    border: 1px solid $light_grey;
    border-radius: 5px;
    font-size: 18px;
    font-weight: 300;
    font-family: $fontBaseV2;
  }
  &.textFilted {
    input.text {
      cursor: text;
    }
  }
  &.error {
    border-color: var(--site-theme-color);
  }

  &.v2 {
    width: auto;
    border: 0 none;
  }

  &.v3 {
    width: auto;
    border: 0 none;
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
    color: var(--site-theme-color);
    text-decoration: underline;
    text-decoration-style: dotted;
    text-underline-offset: 2px;
    height: auto;
    line-height: inherit;
    font-size: inherit;
  }
}

.easy-popup-textfield {
  font-family: 'Libre Franklin', sans-serif;
  padding: 16px 23px;
  background-color: $light_grey;
  border: 1px solid $light_grey;
  border-radius: 5px;
  margin-bottom: 16px;
  color: #000000;
  width: 100%;
  height: 64px;
  font-size: 16px;
  font-weight: 300;

  &:focus {
    background-color: #fff;
  }

  &:hover,
  &:focus {
    border-color: $light_grey;
  }

  @include breakpoint-sm {
    height: 40px;
  }

  &textfield {
    border-radius: 5px;
    padding-top: 12px;
  }
}

.easy-textfield {
  height: 40px;
  width: 220px;
  padding: 0;
  background-color: transparent;
  border: none;
  outline: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  color: black;
  font-size: 15px;
  line-height: 20px;
  font-weight: 400;

  &:hover,
  &:focus {
    border-color: var(--site-theme-color);
  }

  .search {
    // TODO: icon
  }

  &.full-width {
    width: 100%;
  }
}

.easy-checkbox, .easy-radio {
  position: relative;
  width: 16px;
  height: 16px;
  flex-shrink: 0;
  &::before,
  &::after {
    cursor: pointer;
    content: '';
    position: absolute;
  }
  &::before {
    background-color: #c8c8c8;
    border-radius: 1px;
    width: 100%;
    height: 100%;
  }
  &::after {
    height: 100%;
    width: 100%;
    background-image: url('~assets/img/icons/checkmark.svg');
    background-repeat: no-repeat;
    background-position: center;
    transition: background-size 0.1s;
    background-size: 0;
  }

  &:checked {
    &::after {
      background-size: 76%;
    }
  }

  &.v2 {
    &::before {
      background: #fff;
      border-radius: 0;
      border: 1px solid #ddd;
    }
    &:checked {
      &::after {
        background-size: 65%;
      }
    }
  }

  &.xmark {
    &::after {
      background-image: url('~assets/img/icons/close.svg');
    }
  }

  &.xmark, &.checkmark {
    &:checked {
      &::before {
        border: 1px solid transparent;
      }
      &::after {
        background-size: 100%;
      }
    }
  }
}

.easy-radio {
  &, &.v2 {
    &::before,
    &::after {
      border-radius: 50%;
    }

    &:checked {
      &::after {
        background-color: #000;
        width: 8px;
        height: 8px;
        left: 4px;
        top: 4px;
      }
    }
  }
  &.xmark, &.checkmark {
    &:checked {
      &::before,
      &::after {
        border-radius: 0;
      }
    }

    &:checked {
      &::after {
        background-color: transparent;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        border-radius: 0;
      }
    }
  }
}

.focusable {
  padding: 0;
  margin: 0;
  font-weight: inherit;
  .focus {
    width: 0;
    height: 0;
    border: 0;
    margin: 0;
    padding: 0;
    position: absolute;
    opacity: 0;
    pointer-events: none;
  }
}

.easy-context-menu {
  opacity: 0;
  pointer-events: none;
  font-weight: 600;
  width: 180px;
  position: absolute;
  background-color: #fff;
  filter: drop-shadow(rgba(0, 0, 0, 0.3) 0 2px 3px);

  > * {
    color: $blue;
    padding: 9px 10px;
    line-height: 24px;
    &.red {
      color: $red;
    }

    @mixin icon($url) {
      position: relative;
      padding-left: 40px;
      &::before {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 40px;
        content: '';
        background-image: $url;
        background-position: center;
        background-size: auto 16px;
        background-repeat: no-repeat;
      }
    }

    &.edit-icon {
      @include icon(url('../icons/edit.svg'));
    }
    &.archive-icon {
      @include icon(url('../icons/archive.svg'));
    }
    &.delete-icon {
      @include icon(url('../icons/delete.svg'));
    }
    &.downgrade-icon {
      @include icon(url('../icons/downgrade.svg'));
    }
    &.translations-icon {
      // ../icons/translations-tutorial.svg is not found
      @include icon(url('~assets/img/icons/translations-tutorial.svg'));
    }

    &:hover {
      background-color: #f9f9f9;
    }
  }
}

.easy-dialog {
  position: relative;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.2s;
  z-index: 999;
  &.dialog-open {
    opacity: 1;
    pointer-events: all;
  }
  .dialog-container {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .close-button {
      content: '';
      position: absolute;
      right: 30px;
      top: 30px;
      height: 14px;
      width: 14px;
      background: url('~assets/img/icons/close-pop-up.svg');
      background-repeat: no-repeat;
      cursor: pointer;
      outline: none;

      &:hover {
        color: #4594ff;
      }

      .keyname {
        color: #e0e0e0;
      }
    }

    .content {
      @include breakpoint-sm {
        padding: 0 24px;
        width: 100%;
      }
      .text {
        text-align: center;
        font-size: 18px;
        color: #444;
      }
      .buttons {
        padding-top: 40px;
        display: flex;
        justify-content: center;
        button {
          margin-right: 40px;
          outline: none;
          &:last-child {
            margin-right: 0;
          }
        }

        @include breakpoint-sm {
          flex-direction: column;
          row-gap: 10px;

          button {
            margin-right: 0;
          }
        }
      }
      .options {
        padding-top: 30px;
        .option {
          display: flex;
          align-items: center;
          font-weight: 500;
          input[type='checkbox'] {
            margin: 0;
          }
          .label-text {
            padding-left: 10px;
          }
        }
      }
    }
  }
}

// body.easy-popup-open {
//   overflow: hidden;

//   .iorad-embed-widget,
//   .iorad-embed-panel {
//     margin-right: 35px !important; // don't overlap with scrollbar
//   }
// }

body.easy-popup-open {
  .contentClassName {
    // #8892 IOS: popup-header doesn't show because of position:fixed inside an element with padding
    top: 0 !important;
  }
  #userTopNavBar,
  #guestTopNavBar {
    // #8892 IOS: popup-header keeps showing the navbar below with some opacity
    visibility: hidden;
  }
}

.easy-popup {
  display: none;
  flex-direction: column;
  -webkit-flex-direction: column;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  z-index: 5;
  padding: 0 15px;
  font-family: 'Libre Franklin', sans-serif;
  overflow-y: auto;
  transition: left 0.3s ease-out, right 0.3s ease-out;

  &.open {
    cursor: auto;
    display: flex;
    display: -webkit-flex;
    z-index: 999;
  }

  .popup-header {
    font-family: $fontBaseV2;
    font-weight: $semibold;
    font-size: 32px;
    line-height: 40px;
    margin-top: 40px;
    margin-bottom: 32px;
    text-align: center;
    color: #000;
    flex: 0 0 auto;

    &.with-subject {
      margin-bottom: 16px;
    }
  }

  .popup-close {
    position: absolute;
    right: 22px;
    top: 57px;
    font-weight: lighter;
    font-size: 16px;
    color: #0047ff;
    padding-left: 26px;
    z-index: 5;
    cursor: pointer;
    min-height: 22px;
    .keyname {
      color: #e0e0e0;
      display: block;
      text-align: right;
    }

    &:hover {
      color: #ff291c;
      border-bottom: 1px solid rgba(255, 41, 28, 0.5);

      .keyname {
        color: #ff291c;
        opacity: 0.1;
      }
    }
  }

  .gl-content-wrapper {
    @include breakpoint-sm {
      width: 100%;
      padding: 0;
    }
  }

  &.small_width {
    .popup-content {
      width: 425px;
      margin: 0 auto;
    }
  }

  .popup-content {
    .popup-content-inner {
      color: #000000;
      line-height: 24px;
      font-size: 16px;
      font-weight: 300;
      margin-bottom: 42px;

      @include breakpoint-sm {
        margin-bottom: 32px;
      }
    }

    &.noFlex {
      flex: 0 0;
    }

    .message {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 35px;
    }

    .btn-action {
      margin-bottom: 16px;
      height: 64px;
      width: 100%;
      cursor: pointer;
      font-size: 16px;
      font-weight: lighter;
      background-color: #0047ff;

      &:hover {
        background-color: #4594ff;
      }

      .keyname {
        color: #e0e0e0;
      }

      @include breakpoint-sm {
        width: 290px;
        height: 46px;
      }
    }

    .btn-submit {
      margin-bottom: $gl_gap * 2;
      height: 64px;
      width: 100%;
      cursor: pointer;
      font-size: 16px;
      background-color: #0047ff;
      border-radius: 5px;
      border: 1px solid #0047ff;
      color: #fff;

      &:hover {
        background-color: #4594ff;
      }

      .keyname {
        color: #e0e0e0;
      }

      @include breakpoint-sm {
        width: 100%;
        height: 40px;
        line-height: 22px;
      }

      &.btn-fancy-red {
        background-color: #ff6464;
        border: 1px solid #ff6464;

        &:hover {
          background-color: #fe7777;
        }
      }
    }

    .btn-cancel {
      border: 1px solid #e0e0e0;
      background-color: #fff;
      display: block;
      border-radius: 5px;
      text-align: center;
      color: #0047ff;
      cursor: pointer;
      font-size: 16px;
      font-weight: lighter;
      height: 64px;
      width: 100%;
      margin-top: 20%;

      &:hover {
        color: #4594ff;
      }

      @include breakpoint-sm {
        height: 40px;
      }

      .keyname {
        color: #e0e0e0;
      }
    }

    .accepted {
      margin-top: 10px;
      display: inline-block;
    }

    .error {
      border: 1px solid #ff6363 !important;
      color: #ff6363 !important;
    }

    .error-container {
      margin-bottom: 16px;
      margin-top: 8px;
      color: var(--site-theme-color);
      font-size: 16px;
      text-align: center;
      font-family: 'Libre Franklin', sans-serif;
      font-weight: 300;
      line-height: 20px;
    }
  }

  .popup-subject {
    @include roboto_18;
    border: none;
    color: $dark_grey;
    margin-bottom: 3 * $gl_gap;
    text-align: center;
    @include breakpoint-sm {
      margin-bottom: 2 * $gl_gap;
    }
  }

  .popup-footer {
    text-align: center;
    flex: 0 0 auto;
    -webkit-flex: 0 0 auto;
    // margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0px;

      .popup-button {
        margin-bottom: 40px;
      }
    }

    &.condensed {
      height: auto;
      min-height: auto;

      .popup-button {
        margin: math.div($button_offset, 2) auto;
      }
    }

    &.no_margin {
      .popup-button {
        margin-top: 0;
      }
    }

    .popup-button {
      margin: $button_offset auto 0;
      height: 64px;
      display: block;
      border-radius: 5px;
      padding: 22px 0;
      width: 425px;
      max-width: 100%;
      text-align: center;
      color: #0047ff;
      cursor: pointer;
      line-height: 18px;
      font-size: 16px;
      font-weight: lighter;
      outline: none;
      // forces to respect its margin-top
      display: inline-block;
      font-family: $fontBaseV2;

      &:hover {
        color: #ff291c;
        text-decoration: underline;
      }

      .keyname {
        color: #e0e0e0;
      }

      &:nth-child(2) {
        margin: -16px auto 32px;
      }
    }
  }

  @media print {
    background-color: #fff !important;

    .popup-close {
      display: none;
    }

    .popup-footer {
      display: none;
    }
  }

  @media screen and (max-width: 1199px) {
  }

  @media screen and (max-width: 991px) {
  }

  @media screen and (max-width: 767px) {
    left: 0;

    &.mobile_full_width {
      width: 100%;

      .popup-footer {
        .popup-button {
          line-height: 22px;
          width: 100%;
          height: 40px;
          padding: 8px 0;
        }
      }
    }

    .popup-close {
      right: 8px;
      top: 33px;
      width: 26px;
      height: 26px;
      overflow-x: hidden;

      .keyname {
        display: none;
      }
    }
  }
}

.easy-autocomplete {
  & > div {
    // autocomplete root element
    width: 100%;
    position: relative;
    & > div {
      // menu
      margin-top: -16px;
      border-radius: 5px;
      box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
      background: rgba(255, 255, 255, 1);
      position: fixed;
      overflow: auto;
      z-index: 1000;
      top: auto;
      left: auto;
      max-height: 50%;
      font-size: 16px;
      font-weight: normal;
      .menu-item {
        // menu item
        cursor: pointer;
        padding: 10px 20px;
        &.highlight {
          background-color: rgba(0, 144, 226, 0.05);
        }
      }
    }
  }
  input {
    @extend .easy-popup-textfield;
    background: transparent;
    border: none;
    border-bottom: 1px solid $autocompleteInputBorderColor;
    border-radius: 0;
    height: 36px;
    font-size: 14px;
    font-weight: 400;

    &:focus {
      outline: none;
    }
  }
}

$popupHeaderHoverDuration: 300ms;

.tutorial_popup {
  $headerHeight: 48px;
  $headerIconLeftMargin: 24px;

  padding: 0;
  padding-top: $headerHeight;

  .popup-header-wrapper {
    display: flex;
    z-index: 11;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: $headerHeight;
    background: #fff;

    .popup-header-wrapper-main {
      display: flex;
      align-items: center;
      background-color: #f2f2f2; // rgba(0, 0, 0, 0.05) without opacity (given position:fixed)
      width: 100%;
      height: 100%;
      opacity: 1;
      transition: opacity $popupHeaderHoverDuration linear;
    }

    .header-icon {
      margin-left: $headerIconLeftMargin;
      padding-top: 0;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .popup-header {
      font-family: $fontBaseV2;
      font-weight: 600;
      font-size: 15px;
      line-height: 48px;
      color: var(--site-theme-color);
      margin: 0;
      margin-left: 15px;
      padding-top: 0;
    }

    .popup-subject {
      font-family: $fontBaseV2;
      font-size: 15px;
      line-height: 48px;
      color: rgba(0, 0, 0, 0.4);
      flex-grow: 1;
      width: 100%;
      max-height: 100%;
      overflow: hidden;
      pointer-events: none;
      margin-left: 62px;
      margin-right: 165px;
      margin-bottom: 0;
      white-space: nowrap;
      position: relative;
      padding-top: 0;

      &::after {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        width: 31px;
        height: 100%;
        background: linear-gradient(
          to left,
          #f2f2f2 30.81%,
          rgba(255, 255, 255, 0) 99.61%
        );
      }
    }

    .popup-close {
      top: 0px;
      right: 24px;
      padding: 0;
      height: 100%;
      display: flex;
      align-items: center;

      &:hover {
        border: none;
      }
    }

    .close-button-tutorial {
      width: 100%;
      height: 48px;
      line-height: 48px;
      font-family: $fontBaseV2;
      font-weight: 600;
      font-size: 15px;
      color: var(--site-theme-color);
      cursor: pointer;
      text-align: center;
      position: absolute;
      overflow: hidden;
      opacity: 0;
      transition: opacity $popupHeaderHoverDuration linear;

      .popup-close {
        svg {
        }
      }

      &:before {
        content: '';
        background-color: rgba(var(--site-theme-color-rgb), 0.05);
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transform: scale(4) translateX(-100%);
        transition: all 0.5s ease-out;
      }

      .popup-header-returnTo {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        pointer-events: none;

        .popup-header-returnTo-icon {
          display: flex;
          align-items: center;
          margin-left: $headerIconLeftMargin;
          padding-top: 0;
        }

        .popup-header-returnTo-title {
          margin-left: 15px;
          padding-top: 0;
        }
      }

      span {
        position: relative;
        z-index: 1;
        top: 0;
        padding-top: 0;
        display: inline-block;
        height: 100%;
        vertical-align: middle;
      }
    }

    &:not(.mobile) {
      pointer-events: none; // enable after interaction with popup content or footer (#8277)

      &:hover,
      &.closing {
        .close-button-tutorial {
          opacity: 1;

          &::before {
            transform: scale(4) translate(20%);
          }

          .popup-close {
            svg {
              path {
                stroke: var(--site-theme-color);
              }
            }
          }
        }
        .popup-header-wrapper-main {
          opacity: 0;
        }
      }
    }

    &.mobile {
      .close-button-tutorial {
        visibility: visible;
        opacity: 1;
        transition: none;
        color: black;

        .popup-header-returnTo {
          display: none;
        }
      }
    }

    @include breakpoint-sm {
      .popup-subject,
      .popup-header {
        display: none;
      }

      .popup-close {
        right: 5px;

        svg {
        }
      }
    }
  }

  .popup-content {
    padding: 0 40px;

    @include breakpoint-sm {
      padding: 0 25px;
    }
  }

  &.bodyInteracted .popup-header-wrapper {
    pointer-events: all;
  }
}
