.react-datepicker {
  width: 100%;
  font-size: 15px;
  line-height: 20px;
  font-weight: 600;
  font-family: 'Libre Franklin', sans-serif;

  .react-datepicker__navigation {
    width: 56px;
    height: 56px;
    top: 0;

    &.react-datepicker__navigation--previous {
      left: 0;
    }
    &.react-datepicker__navigation--next {
      right: 0;
    }
  }

  .react-datepicker__month-container {
    width: 100%;

    .react-datepicker__current-month {
      font-size: 15px;
      line-height: 20px;
      font-weight: 600;
    }

    .react-datepicker__day-names {
      display: none;
    }
  }

  .react-datepicker__header {
    background-color: white;
    border-radius: 0;
    border: none;
    padding: 18px 0;
  }

  .react-datepicker__month {
    margin: 10px;
  }

  .react-datepicker__day {
    width: calc(100% / 7);
    line-height: 40px;
    border-radius: 0 !important;
    margin: 0;
    position: relative;
    font-size: 15px;
    font-weight: 600;

    &.react-datepicker__day--today {
      font-weight: 600;
    }

    &.react-datepicker__day--selected,
    &.react-datepicker__day--keyboard-selected,
    &.react-datepicker__day--in-range,
    &:not([aria-disabled='true']):hover {
      background-color: #f4f4f4;
      color: black;
    }

    &.react-datepicker__day--in-selecting-range {
      background: #{'rgba(var(--site-theme-color-rgb), 0.05)'};
      color: black;
    }

    &.react-datepicker__day--selecting-range-start,
    &.react-datepicker__day--selecting-range-end,
    &.react-datepicker__day--range-start,
    &.react-datepicker__day--range-end {
      &::after {
        content: '';
        position: absolute;
        width: calc(100% - 2px);
        height: calc(100% - 2px);
        top: 1px;
        left: 1px;
        border: 1px solid black;
      }
    }

    &.react-datepicker__day--selecting-range-end:not([aria-disabled='true']) {
      background: #{'rgba(var(--site-theme-color-rgb), 0.05)'};
      &:after {
        border-color: var(--site-theme-color);
      }
    }
  }
}
