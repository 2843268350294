@import '~theme/modules/variables';

.sticky-table {
  width: 100%;
  height: 100%;

  .head {
    overflow: hidden;
  }

  thead {
    border: 1px solid #dddddd;
    border-left: none;
    border-right: none;

    td {
      height: 34px;
      padding: 2px 10px;
      color: #aaa;
      font-size: 10px;
      font-weight: 700;
      letter-spacing: 2px;
      text-transform: uppercase;
      white-space: nowrap;
      overflow: hidden;
      box-sizing: border-box;
    }
  }

  td {
    &.action {
      width: 60px;
      min-width: 60px;
      padding: 0;
    }
  }

  table {
    border: none;
    width: 100%;
    margin: 0;
    // table-layout: fixed;
  }

  .body {
    height: calc(100% - 35px);
    overflow-y: auto;
    thead.original {
      opacity: 0;
      height: 0;
      border: 0;
      td {
        line-height: 0;
        height: 25px;
      }
    }
  }

  .loading-box {
    padding: 20px 0;
    text-align: center;
  }
  .scrollbar > div:first-child {
    top: -25px !important; // shift original header for hidding
  }
  tbody {
    td {
      padding: 15px 10px;
      font-size: 14px;
      line-height: 24px;
      height: 80px;
      &.action {
        position: relative;
        background: transparent url('../icons/menu.svg') no-repeat center;
        background-size: auto 16px;

        .focusable {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          padding: 0;
          margin: 0;
          cursor: pointer;
          .focus {
            width: 0;
            height: 0;
            border: 0;
            margin: 0;
            padding: 0;
            position: absolute;
            opacity: 0;
            pointer-events: none;
          }
        }

        .easy-context-menu {
          $openTime: .1s;
          $width: 180px;
          transition: opacity $openTime, transform $openTime,
            filter $openTime;
          width: $width;
          left: calc(50% - 10px);
          top: calc(50% - 8px);
          transform: translate(-98%, 0);
          z-index: 1;

          &.right {
            left: calc(100% + #{$width});
          }
        }

        &.top {
          .easy-context-menu {
            top: auto;
            bottom: calc(50% - 8px);
          }
        }

        &.open {
          background-image: url('../icons/menu-selected.svg');
          .easy-context-menu {
            opacity: 1;
            transform: translate(-100%, 0);
            pointer-events: all;
          }
        }
      }
    }
  }

  .scrollbar {
    $vertical: '> div + div + div';
    #{$vertical} {
      opacity: 0;
      transition: opacity .3s ease-in-out;
    }
    &:hover {
      #{$vertical} {
        opacity: 1;
      }
    }
  }

  &.selectable {
    tbody {
      tr {
        cursor: pointer;
        &:hover {
          background-color: #f9f9f9;
        }
        &.selected {
          background-color: #f5fcfc;
        }
      }
    }
    td {
      &.select {
        width: 60px;
        text-align: center;
        padding-left: 20px;
        padding-right: 5px;
      }
    }
  }
}
